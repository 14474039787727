//--------------------------------//
// Add Pound # sign to headers  
//--------------------------------//
const alloyMain = document.querySelector('main');
const alloyAllHeaders = alloyMain.querySelectorAll('h1, h2, h3, h4');
// convert string to URL ready sting
function convertToSlug(Text) {
  return Text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

alloyAllHeaders.forEach((item, index) => {
  // Add ID to all found headers
  item.setAttribute('id', `${convertToSlug(item.innerText)}`);
  // Create <a href></a> element
  const anchor = document.createElement("a");
  anchor.classList.add('alloy-headline-anchor');
  anchor.href = `#${convertToSlug(item.innerText)}`;
  anchor.textContent = `#`;
  // Add <a href></a> to header
  item.append(anchor);
})
// END Add Pound # sign to headers  --------------//
