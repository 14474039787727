import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Draggable } from "gsap/Draggable";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(
  ScrollTrigger,
  // Draggable,
  Observer,
  ScrollToPlugin
);

ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

let mm = gsap.matchMedia();
// END Setup 🧦 GSAP -------------------------------------//
//--------------------------------//
// Site animatie 
//--------------------------------//
mm.add("(min-width: 450px)", () => { // * also edit _acf.scss on line 243
  // 🏷️ Label animatie over de hele site
  ScrollTrigger.batch([".item .label", ".alloy-caption .label", ".wp-caption figcaption"], {
    onEnter: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.2 }),
    onLeave: batch => gsap.to(batch, { autoAlpha: 0, stagger: 0.2 }),
    onEnterBack: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.2 }),
    onLeaveBack: batch => gsap.to(batch, { autoAlpha: 0, stagger: 0.2 }),
    start: "top-=200px center",
    end: "bottom+=100px center",
    // markers: true,
  });
});
// END site animatie --------------//

//--------------------------------//
// Slider 
//--------------------------------//
document.querySelectorAll(".slider").forEach((container) => {
  // Setup variables
  const items = container.querySelectorAll(".slide"),
    itemsMax = items.length - 1;
  const next = container.querySelector(".next"),
    prev = container.querySelector(".prev"),
    overlayRight = container.querySelector(".overlay .right"),
    overlayLeft = container.querySelector(".overlay .left"),
    overlayDown = container.querySelector(".overlay .down"),
    dots = container.querySelector(".dots");
  let index = 0;
  const move = 100;
  const timer = 5000;
  const duration = 0.5;
  let autoplay;
  let autoplayOn = false;
  let drag = false;

  if (container.classList.contains('autoplay')) {
    autoplayOn = true;
  }
  //--------------------------------//
  // 💾 Inital setup
  //--------------------------------//
  function init() {
    // Create 🟣 Dots
    items.forEach((item, index) => {
      const dot = document.createElement("li");
      if (index === 0) dot.classList.add("active"); // Set the first dot to active
      // Allow clicking the dots
      dot.addEventListener("click", () => {
        slideLogic(false, index);
      });

      if (dots) dots.appendChild(dot);
    });
    // Animate the first element in
    gsap.set(items, { autoAlpha: 0 });
    gsap.set([".slider", items[index]], { autoAlpha: 1 });
    if (autoplayOn) {
      gsap.from(items[index], { autoAlpha: 0, x: move });
      pan(index);
      autoplay = window.setTimeout(slideLogic, timer);
    }
  }
  // Run inital setup
  init();
  // END 💾 Inital setup --------------//

  //--------------------------------//
  // ☝️ Touch
  //--------------------------------//
  Observer.create({
    target: container,
    type: "pointer",
    onLeft: () => {
      if (!drag) {
        window.clearTimeout(autoplay);
        slideLogic(true);
      }
      drag = true;
    },
    onRight: () => {
      if (!drag) {
        window.clearTimeout(autoplay);
        slideLogic(false);
      }
      drag = true;
    },
  });
  // END ☝️ Touch  --------------//

  //--------------------------------//
  // Set active 🟣 dot
  //--------------------------------//
  function dotActive(index) {
    const dotsAll = dots.querySelectorAll("li");
    dotsAll.forEach((dot) => {
      dot.classList.remove("active");
    });
    dotsAll[index].classList.add("active");
  }
  // END Set active 🟣 dot --------------//

  //--------------------------------//
  // 🖼️ Slide animation
  //--------------------------------//
  function slideAnimation(index, moveIn, outIn) {
    // Reset the properties you are animating below
    // Ohter wise they animate from that positon (probalby not visable)
    gsap.set(items, { xPercent: 0, scale: 1 });
    gsap.set(items[moveIn], { autoAlpha: 1 });
    gsap.set(items[moveIn].querySelector("img"), { xPercent: 0 });
    // The animation
    const tl = gsap.timeline({
      // repeatRefresh: true,
      defaults: {
        duration: duration
      },
      onStart: animationStart,
      onComplete: () => {
        animationDone(moveIn)
        container.classList.remove("running");
      }
    });
    // Move out slide
    // tl.to(items[index], { xPercent: outIn[0] });
    tl.to(items[index], { opacity: 1 });
    // move in slide
    // tl.from(items[moveIn], { xPercent: outIn[1] }, "<");
    tl.from(items[moveIn], { opacity: 0 }, "<");
    // Animate text
    tl.set(items[index], { autoAlpha: 0 }); // Hide moved out slide
  }
  function animationStart() {
    container.classList.add("running");
  }
  function pan(moveIn) {
    gsap.to(items[moveIn].querySelector("img"), {
      duration: 15,
      xPercent: -15,
      ease: "none",
    });
  }
  function animationDone(moveIn) {
    // console.warn(moveIn);
    container.classList.remove("running");
    toggleButtons();
    if (autoplayOn) {
      autoplay = window.setTimeout(slideLogic, timer);
    }

    gsap.set(items, { x: 0 });
    if (autoplayOn) {
      pan(moveIn);
    }
    drag = false;
  }

  // END 🖼️ Slide animation --------------//

  //--------------------------------//
  // Slider 🎛️ logic
  //--------------------------------//
  function slideLogic(prev, customMoveIn) {
    toggleButtons(); // Disable buttons
    window.clearTimeout(autoplay); // disable autoplay
    let outIn = [-move, move];
    if (prev) outIn.reverse();
    let moveIn;
    // Check if moveIn is passed with the function
    if (typeof customMoveIn === "undefined") {
      if (prev) {
        moveIn = index === 0 ? itemsMax : index - 1;
      } else {
        moveIn = index === itemsMax ? 0 : index + 1;
      }
    } else {
      moveIn = customMoveIn;
    }
    if (dots) dotActive(moveIn); // Set active dot
    slideAnimation(index, moveIn, outIn); // Animation function
    // Changing the next index
    if (typeof customMoveIn === "undefined") {
      if (prev) {
        index === 0 ? (index = itemsMax) : index--;
      } else {
        index === itemsMax ? (index = 0) : index++;
      }
    } else {
      index === itemsMax ? (index = 0) : (index = customMoveIn++);
    }
  }
  // END Slider 🎛️ logic --------------//

  //--------------------------------//
  // Button 🎛️ control
  //--------------------------------//
  function toggleButtons() {
    if (next) next.disabled = !next.disabled;
    if (prev) prev.disabled = !prev.disabled;
  }
  if (next) next.addEventListener("click", () => slideLogic());
  if (prev) prev.addEventListener("click", () => slideLogic(true));
  if (overlayRight) overlayRight.addEventListener("click", () => slideLogic());
  if (overlayLeft) overlayLeft.addEventListener("click", () => slideLogic(true));
  if (overlayDown) overlayDown.addEventListener("click", () => {
    gsap.to(window, {
      duration: 0.7,
      // ease: "power2.out",
      ease: "power2.in",
      // ease: "none",
      scrollTo: "#main-content",
      onStart: () => { document.body.classList.remove('smooth') },
      onComplete: () => { document.body.classList.add('smooth') }
    });

  });
  // END Button 🎛️ control --------------//
});


// END Slider --------------//

