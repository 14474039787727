//------------------------------------------------------//
// Slide menu 🎛️ toggle  
//------------------------------------------------------//
const navigationSlideToggle = document.querySelector('#navigation-slide-toggle');
const navigationSlide = document.querySelector('#navigation-slide');
const body = document.querySelector('body');

// On 🐭 click add class to body and toggle 🦻 ARIA lables
navigationSlideToggle.addEventListener('click', () => {
  body.classList.toggle('showNavigationSlide');
  navigationSlideToggle.setAttribute('aria-expanded', navigationSlideToggle.getAttribute('aria-expanded') == 'true' ? 'false' : 'true');
});
// END Slide menu 🎛️ toggle  -------------------------------------//

//------------------------------------------------------//
// On browser 📜 scroll full window height  
//------------------------------------------------------//
const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

window.onscroll = function (ev) {
  if (window.scrollY >= width / 4) {
    body.classList.add('showScrolled');
  } else {
    body.classList.remove('showScrolled');
  }
};
// END On browser 📜 scroll full window height  -------------------------------------//

